import React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import { useForm } from "react-hook-form"
import Cookies from "universal-cookie"

import IconArrowRight from "../../images/icon-arrow-right.png"

const AgegateModal = ({ setAgeCheck }) => {
  let ageCookie = new Cookies()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = data => {
    console.log(data)
    document.querySelector("body").className = ""
    ageCookie.set("isOldEnough", "yes", {
      path: "/",
      maxAge: 2592000,
      // secure: true,
      // httpOnly: true,
      // sameSite: true,
    })
    setAgeCheck(false)
  }
  return (
    <>
      <section className="agegate-wrapper">
        <div className="agegate-wrapper-inner d-flex justify-content-center">
          <div className="agegate-hldr d-flex justify-content-center align-items-center text-center">
            {/* <div className="close-btn">
              <StaticImage src="../../images/icon-close.png" alt="Icon Close" />
            </div> */}
            <div className="content">
              <h3>Just a sec...</h3>
              <p>
                You must be 18 years or over to enter any of our competitions.
                <br />
                Please confirm your age by ticking the box below.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 form-check">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      {...register("ageConfirm", { required: true })}
                    />{" "}
                    I am 18 years or older
                  </label>
                  {errors.ageConfirm && (
                    <span className="d-block input-error">
                      Age confirmation is required
                    </span>
                  )}
                </div>
                <button type="submit" className="btn">
                  Submit
                  <img src={IconArrowRight} alt="Icon Arrow Right" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AgegateModal
