import React from "react"

const Footer = () => {
  return (
    <>
      <footer className="main-footer d-block bg-transparent py-5">
        <div className="container">
          <div className="text-center">
            <ul>
              <li>
                <a
                  href="https://www.mars.com/privacy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Statement
                </a>
              </li>
              <li>
                <a
                  href="https://www.mars.com/cookies-english"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Cookies Notice
                </a>
              </li>
              <li>
                <a
                  href="https://www.mars.com/legal"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Legal
                </a>
              </li>
              <li>
                <a
                  href="https://www.martiansreunited.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Mars Alumni
                </a>
              </li>
              <li>
                <a
                  href="https://www.mars.com/legal/ca-supply-chain-act"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  CA Supply Chain Transparency Act
                </a>
              </li>
              <li>
                <a
                  href="https://www.mars.com/modern-slavery-act"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Modern slavery
                </a>
              </li>
              <li>
                <a
                  href="https://www.mars.com/accessibility"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Accessibility
                </a>
              </li>
              <li>
                <a
                  href="https://gbr.mars.com/contact-us"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <p className="copyright mb-0">
              &copy; Treats by Mars 2022 | t:{" "}
              <a href="tel:08009520077">0800 952 0077</a>
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
